<template>
    <div>
        <multiselect v-model="selected" :class="validateError != '' ? 'box-border-color' : ''"
            :clear-on-select="!multiple" :close-on-select="!multiple" :deselect-label="''" :multiple="multiple"
            :options="options" :placeholder="$t('select')" :select-label="''" :selected-label="''" label="text"
            track-by="value" @input="handleInput($event, multiple)">
            <template slot="selection" slot-scope="{ values, search, isOpen}" v-if="multiple">
                <span v-if="values.length && !isOpen" class="multiselect__single">
                    {{ translateNSelected(values) }}
                </span>
            </template>
            <span slot="noOptions">{{ $t('no_options') }}</span>
            <span slot="noResult">{{ $t('no_result') }}</span>
        </multiselect>
        <span class="invalid-feedback-custom" v-show="validateError" v-html="validateError" />
    </div>
</template>

<script>
// Helpers
import translateNSelected from '@/helpers/translateNSelected';
import handleInput from '@/helpers/handleInput';
import setSelected from '@/helpers/setSelected';
import ParameterService from "@/services/ParameterService";
import qs from "qs"

export default {
    props: {
        value: {
            default: null
        },
        valueType: {
            type: String,
            default: 'id'
        },
        multiple: {
            type: Boolean,
            default: false
        },
        validateError: {
            type: String,
            default: ''
        },
        multipleReturnType: {
            type: String,
            default: 'array',
        },
    },
    watch: {
        value: function (newValue) {
            this.selected = this.setSelected(newValue, this.options, this.multiple)
        }
    },
    data() {
        return {
            selected: null,
            options: [
            ]
        }
    },
    created() {
        this.selected = this.setSelected(this.value, this.options, this.multiple)
    },
    methods: {
        translateNSelected,
        handleInput,
        setSelected,
        getOptions() {
            const config = {
                params: {
                    sort: this.sort,
                    limit: -1
                },
                paramsSerializer: (params) => qs.stringify(params, { encode: false })
            }

            ParameterService.getItems('classes', config)
                .then(response => {
                    const data = response.data.data;
                    if (data && data.items) {
                        data.items.map(item => {
                            this.options.push({
                                value: item.code,
                                text: item.name,
                            });
                        });
                    }
                })
                .then(() => {
                    this.selected = this.setSelected(this.value, this.options, this.multiple, this.multipleReturnType);
                })
        }
    },
    mounted(){
        this.getOptions()
    }
}
</script>
