<template>
	<div class="max">
		<div class="spinner over" v-show="formLoading">
			<div class="d-flex justify-content-center py-5">
				<b-spinner label="Spinning" class="text-primary"></b-spinner>
			</div>
		</div>
		<div v-show="!formLoading">
			<b-row>
				<b-col cols="12">
					<h5 class="font-weight-bold">{{ $t('summary') }}</h5>
					<div class="mb-5"
					     v-if="studentLists.summary && studentLists.summary.length > 0">
						<b-table bordered striped hover
						         :fields="summaryFields"
						         :items="studentLists.summary"
						>
							<template #head(repeat_student_count)="data">
								<span v-html="data.label.toUpperCase()"></span>
							</template>
							<template #head(first_student_count)="data">
								<span v-html="data.label.toUpperCase()"></span>
							</template>
							<template #head(total_student_count)="data">
								<span v-html="data.label.toUpperCase()"></span>
							</template>
						</b-table>
					</div>
					<div class="d-flex mb-2">
						<div class="flex-grow-1 d-flex align-items-center">
							<h5 class="font-weight-bold m-0">{{ $t('students') }}</h5>
						</div>
                        <div class="d-flex">
                            <json-excel :data="studentLists.students"
                                        :fields="studentFieldsJson"
                                        type="xls">
                                <b-button variant="primary" class="mr-2">
                                    <i class="ri-file-excel-2-line mr-2"></i> {{ $t('excel') }}
                                </b-button>
                            </json-excel>
                            <b-button variant="primary" @click="print">
                                <i class="ri-printer-line mr-2"></i> {{ $t('print') }}
                            </b-button>
                        </div>
					</div>
					<div v-if="studentLists.students && studentLists.students.length > 0" id="printMe">
						<b-table bordered striped hover
						         :fields="studentFields"
						         :items="studentLists.students"
						         caption-top
						>
						</b-table>
					</div>
				</b-col>
			</b-row>
		</div>
	</div>
</template>

<script>
	// Components
	import StaffAutoComplete from "@/components/interactive-fields/StaffAutoComplete";

	// Services
	import SectionService from "@/services/SectionService"

	// Other
	import {ValidationProvider, ValidationObserver} from "vee-validate"
	import JsonExcel from "vue-json-excel";

	export default {
		components: {
			StaffAutoComplete,

			ValidationProvider,
			ValidationObserver,
			JsonExcel
		},
		props: {
			formId: {
				type: Number
			}
		},
		data() {
			return {
				section: {},
				studentLists: {
					summary: [],
					students: [],
				},
				summaryFields: [
					{
						key: 'faculty_code_name',
						label: this.$t('faculty_code_name'),
						sortable: true
					},
					{
						key: 'program_code_name',
						label: this.$t('program_code_name'),
						sortable: true
					},
					{
						key: 'class',
						label: this.$t('class'),
						sortable: true,
						thClass: 'text-center',
						tdClass: 'text-center'
					},
					{
						key: 'repeat_student_count',
						label: this.$t('repeat_student_count'),
						sortable: true,
						thClass: 'text-center',
						tdClass: 'text-center'
					},
					{
						key: 'first_student_count',
						label: this.$t('first_student_count'),
						sortable: true,
						thClass: 'text-center',
						tdClass: 'text-center'
					},
					{
						key: 'total_student_count',
						label: this.$t('total_student_count'),
						sortable: true,
						thClass: 'text-center',
						tdClass: 'text-center'
					}
				],
				studentFields: [
					{
						key: 'faculty_code_name',
						label: this.$t('faculty_code_name'),
						sortable: true
					},
					{
						key: 'program_code_name',
						label: this.$t('program_code_name'),
						sortable: true
					},
					{
						key: 'student_number',
						label: this.$t('student_number'),
						sortable: true
					},
					{
						key: 'name',
						label: this.$t('name'),
						sortable: true
					},
					{
						key: 'surname',
						label: this.$t('surname'),
						sortable: true
					},
					{
						key: 'class',
						label: this.$t('class'),
						sortable: true,
						thClass: 'text-center',
						tdClass: 'text-center'
					},
					{
						key: 'section',
						label: this.changeText(this.$t('section')),
						sortable: true,
						thClass: 'text-center',
						tdClass: 'text-center'
					},
					{
						key: 'course_status',
						label: this.$t('status'),
						sortable: true,
					}
				],
				studentFieldsJson: {
          [this.$t('faculty_code_name')]: 'faculty_code_name',
          [this.$t('program_code_name')]: 'program_code_name',
          [this.$t('student_number')]: 'student_number',
          [this.$t('name')]: 'name',
          [this.$t('surname')]: 'surname',
          [this.$t('class')]: 'class',
          [this.$t('section')]: 'section',
          [this.$t('course_status')]: 'course_status'
				},
				formLoading: false,
			}
		},
		created() {
			if (this.formId) {
				this.getSection(this.formId);
			}
		},
		methods: {
			formDataClear() {
				this.formData = {
					instructor_id: null,
				}
			},

			getSection(id) {
                if(this.checkPermission('section_studentsreport')){
                    this.formLoading = true
                    SectionService.get(id)
                                  .then(response => {
                                      this.section = response.data.data;
                                  })
                                  .then(() => {
                                      this.getStudentList(this.section.id);
                                  })
                                  .catch(e => {
                                      this.$router.push('/404')
                                  })
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }

			},
			getStudentList(id) {
				SectionService.studentsReport(id)
				              .then(response => {

					              this.studentLists = response.data.data;
				              })
				              .finally(() => {
					              this.formLoading = false;
				              })
			},

			async print () {
                if(this.checkPermission('section_studentsreport')){
                    await this.$htmlToPaper('printMe');
                } else {
                    this.$toast.error(this.$t('you_are_not_authorized_for_this_operation'))
                }

			}
		}
	}
</script>

