<template>
	<div>
		<b-form-select v-model="selected"
		               :options="options"
		               :class="validateError ? 'box-border-color' : ''"
		               @input="handleInput"
		               @change="changedValue"
                       :disabled="disabled"
		/>
		<span class="invalid-feedback-custom" v-if="validateError" v-html="validateError"/>
	</div>
</template>

<script>
	export default {
		props: {
			value: {
				default: null
			},
			validateError: {
				type: String,
				default: ''
			},
            disabled: {
                type: Boolean,
                default: false
            }
		},
		watch: {
			value: function (newValue) {
				this.selected = newValue
			}
		},
		data() {
			return {
				selected: null,
				options: [
					{
						value: null,
						text: this.$t('select'),
					},
					{
						value: 1,
						text: this.$t('credit'),
					},
					// {
					// 	value: 2,
					// 	text: this.$t('practical'),
					// }
				]
			}
		},
		created() {
			this.selected = this.value
		},
		methods: {
			handleInput(event) {
				this.$emit('input', event)
			},
			changedValue(event) {
				this.$emit('changedValue', event)
			}
		}
	}
</script>
